import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Title, Box, List } from "../components/Core";
import styled from "styled-components";

const BoxStyled = styled(Box)`
  .block-title {
    color: ${({ theme }) => theme.colors.dark};
    font-size: 21px;
    font-weight: 700;
    line-height: 34px;
  }

  p {
    color: ${({ theme }) => theme.colors.text};
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
    margin-bottom: 15px;
  }
`;


const AssetsBestPractices = () => {
  return (
    <>
      <PageWrapper footerDark>
        <BoxStyled>
          <div className="pt-5 mt-5"></div>
          <Container>
            <Row className="justify-content-center">
              <Col lg="8">
                <Box className="mb-5">
                  <div className="mb-5">
                    <Title variant="hero">Asset Productivity Enhancement</Title>
                    <h2 className="block-title mb-1">
                      A Bulletproof Guide to Optimal Performance in Your
                      Business
                    </h2>
                    <p>
                      There’s a common misconception that maintenance is an act
                      of fixing, when in actual fact, it’s quite the opposite.
                    </p>
                    <p>
                      Let’s say you’re managing a ranch, and your
                      responsibilities include ploughing fields using hightech
                      equipment. You’re using this piece of equipment every day,
                      which means its miles keep increasing and you’ll soon have
                      to do some maintenance to keep it in tip-top condition. If
                      you don’t, the equipment might break down or malfunction,
                      and you’re left without your trusty gear for at least a
                      week or two.
                    </p>
                    <p>
                      But that’s not where it ends. If this happens, you’ll be
                      forced to put all respective operations on hold, placing
                      all prospects a week behind schedule. The turn of events
                      are endless from here, but the bottom line is that a
                      simple equipment service every now and then would’ve
                      either avoided the breakdown or could’ve highlighted an
                      underlying problem – and your asset would’ve been in
                      excellent condition by the time it was due to plough.
                    </p>
                    <p>
                      This scenario is relevant in all lines of work. In most
                      cases, simple maintenance is all that’s needed to avoid a
                      repair altogether. That’s what we call “preventive
                      maintenance”, and it could mean the difference between
                      downtime and optimal productivity.
                    </p>
                    <p>
                      At the end of the day, your metaphorical plougher was
                      maintained, and no other components were harmed due to a
                      preventable malfunction – ultimately saving you from huge
                      financial burdens associated with repairs
                    </p>
                  </div>
                  <div className="mb-5">
                    <Box>
                      <Title variant="hero">
                        Asset Productivity Enhancement Using Five Bulletproof
                      </Title>
                    </Box>
                  </div>
                  <div className="mb-5">
                    <h2 className="block-title mb-4">
                      Preventive Maintenance Practices
                    </h2>
                    <List>
                      <li>Data-Driven Prepping</li>
                      <p>
                        The key to making informed decisions is having concrete
                        data to back it up. You can’t try to fix the wipers when
                        the engine is malfunctioning. In other words,
                        pinpointing which components need to be maintained in
                        order for other functions to run successfully using
                        detailed analytics is always the best course of action
                        before you start doing maintenance.
                      </p>
                      <p>
                        Once you’ve established which components take priority,
                        which need attention less often, and how to go about
                        ensuring both run at optimal performance, you’ll finally
                        start making progress. But how can this be done when you
                        have an entire arsenal of assets to take care of?
                      </p>
                      <p>
                        That’s where tracking, metrics, and organized data come
                        in handy. Having a clear and concise system gives you a
                        bird’s eye view of the average lifecycle of each asset.
                        This, in turn, helps you best establish which assets
                        require the most attention and how often.
                      </p>
                      <p>
                        For example: You run a multi-operational manufacturing
                        firm. You have at least twenty pieces of equipment
                        running in unison, each performing a specific task.
                        Though they may run in harmony, each piece of equipment
                        has its own lifecycle and, therefore, its own
                        maintenance requirements. Having a clear idea of each
                        piece of equipment’s lifecycle will certainly be the
                        first step in planning and projecting respective
                        maintenance procedures – or even potential replacements.
                      </p>
                      <li>A Schedule a Day Keeps Malfunctioning at Bay</li>
                      <p>
                        So. You’ve collected the data, compared the variables,
                        and have a clear idea of what you’re dealing with. Now
                        you can go about planning an organized preventive
                        maintenance schedule
                      </p>
                      <p>
                        This way, you’ll know where you can cut costs, ensure
                        asset productivity enhancement, and ultimately increase
                        the efficiency of your equipment and the teams’. After
                        all, your crew is the backbone to managing all
                        operations. If a piece of equipment malfunctions, the
                        people who depend on it also get the short end of the
                        stick.
                      </p>
                      <p>
                        Always make sure you’re tracking the data, prioritized
                        maintenance schedules, and asset performance indicators!
                      </p>
                      <li>Inventory, Inventory, and More Inventory!</li>
                      <p>
                        Have you ever started working on a project with the best
                        intentions in mind, only to realize that one component
                        you need is out of stock? Frustration kicks in, blood
                        starts boiling, and your internal processors start
                        seeing the entire project as a drawn-out undertaking.
                      </p>
                      <p>
                        A comprehensive inventory tracking system gives you
                        peace of mind knowing you have all you need to start a
                        project successfully. So, the first course of action
                        would be to set up an ideal inventory list of every
                        single component you need to get the job done – from the
                        smallest screw, to the screwdriver needed to insert the
                        screw, and beyond. From here, you can decide on the best
                        inventory management system to keep you clued up on
                        every part.
                      </p>
                      <p>
                        Next, simply track your inventory and schedule an
                        ordering system that provides the parts well before they
                        run out.
                      </p>
                      <li> Tracking Reduces Slacking</li>
                      <p>
                        The final key to unlocking full asset productivity
                        enhancement is keeping an eye on progress. This not only
                        means you can nip an underlying problem in the bud, but
                        also gives you a general idea of how successful your
                        preventive maintenance integration is. From tracking
                        KPIs to setting important goals, monitoring is an
                        essential part of improving each facet of your business
                      </p>
                      <p>
                        KPI tracking is yet another way you can collect concrete
                        data to increase overall productivity in your business –
                        which makes using a bulletproof system oh-so-important.
                      </p>
                      <p>
                        “Never stop investing. Never stop improving. Never stop
                        doing something new.” - Bob Parsons
                      </p>
                    </List>
                  </div>
                  <div className="contant-block-4">
                    <h2 className="block-title mb-3">
                      From Malfunction to Fully-Fledged Production
                    </h2>
                    <p>
                      As we’ve already established, preventive maintenance is
                      about a lot more than fixing a problem. It’s about
                      identifying variables, implementing schedules, investing
                      in the bottom line, and monitoring your implementations to
                      continue driving your business into the future.
                    </p>
                  </div>
                </Box>
              </Col>
            </Row>
          </Container>
        </BoxStyled>
      </PageWrapper>
    </>
  );
};
export default AssetsBestPractices;
